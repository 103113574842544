<template>
  <div>
    <div class="skeleton-border flex flex-col items-center mt-8" style="width: 100%; height: 300px;">
      <div class="flex justify-between w-full" style="height: 8%">
        <div class="skeleton-box rounded-md w-full" style="width: 30%;"></div>
        <div class="skeleton-box rounded-md w-full" style="width: 22%;"></div>
      </div>
      <div class="flex justify-between mt-8 w-full h-full">
        <div class="skeleton-box rounded-md" style="width: 50%; height: 100%"></div>
        <div class="mx-5 border-gray-5" style="border-right-width: 1px;"></div>
        <div class="skeleton-box rounded-md" style="width: 50%; height: 100%"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Shimmer"
}
</script>

<style lang="scss" scoped>
.skeleton-border {
  @apply border;
  @apply rounded-md;
  @apply border-gray-5;

  border-width: 1px;
  padding: 1rem;
}

.skeleton-box {
  @apply bg-whiteShimmer;

  display: inline-block;
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(white, 0) 0,
        rgba(white, 0.2) 25%,
        rgba(white, 0.5) 60%,
        rgba(white, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
