









































































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@/app/ui/components/Loading/index.vue'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import MembershipItem from '../components/ListItem/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Shimmer from '@/app/ui/views/LoyaltyProgram/Membership/components/Shimmer/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import AddCircle from '@/app/ui/assets/add_circle.vue'
import controller from '@/app/ui/controllers/LoyaltyProgramMembershipController'
import { ListLoyaltyProgramMembership } from '@/domain/entities/LoyaltyProgramMembership'
import { Utils } from '@/app/infrastructures/misc';
import { QueryParamsMembership } from '@/app/infrastructures/misc/Constants/loyaltyProgram'

@Component({
  name: 'LoyaltyProgramMembership',
  components: {
    AddCircle,
    Button,
    EmptyState,
    Loader,
    MembershipItem,
    Shimmer,
    Tabs,
    TextInput,
    PaginationNav
  }
})
export default class LoyaltyProgramMembership extends Vue {
  controller = controller

  // START states
  currentTab = 0
  parameters: QueryParamsMembership = {
    membershipProgramId: null,
    isActive: true,
    page: 1,
    perPage: 10,
  }

  cacheSearchProgramID = ''
  searchProgramID = ''
  // END states
  itemTabs = [
    { label: 'Active', value: 0 },
    { label: 'Not Active', value: 1 }
  ]

  created(): void {
    const queries = this.$route.query as Record<string, never>
    this.parameters = {
      membershipProgramId: queries.membershipProgramId,
      isActive: queries.isActive === 'true',
      page: queries.page || 1,
      perPage: queries.perPage || 10,
    }
    this.searchProgramID = queries.membershipProgramId
    this.cacheSearchProgramID = queries.membershipProgramId
    if (queries.isActive) {
      this.currentTab = queries.isActive === 'true' ? 0 : 1
    }
    controller.getAll(this.params)
  }

  get listData(): ListLoyaltyProgramMembership {
    return controller.listData
  }

  get params(): QueryParamsMembership {
    return {
      membershipProgramId: this.cacheSearchProgramID,
      isActive: this.currentTab === 0,
      page: this.parameters.page,
      perPage: this.parameters.perPage,
    }
  }

  searchOnEnter(): void {
    this.cacheSearchProgramID = this.searchProgramID
    this.onParamsChanged('membershipProgramId', this.searchProgramID)
    controller.getAll(this.params)
  }

  paginationOnEnter(): void {
    this.onParamsChanged('perPage', this.params.perPage)
    this.onParamsChanged('page', this.params.page)
    controller.getAll(this.params)
  }

  onParamsChanged(key: string, val: string | boolean | undefined | number): void {
    const url = new URL(location.href)
    url.searchParams.set(key, String(typeof val === 'undefined' ? '' : val))
    history.pushState(null, '', url.toString());
  }

  setErrorMessage(): Record<string, string> {
    if (this.cacheSearchProgramID && this.cacheSearchProgramID !== '') {
      return {
        text: 'Keyword Not Found',
        desc: 'Make sure the keywords you are looking for are correct'
      }
    }

    return {
      text: `${this.currentTab === 0 ? 'Active' : 'Not Active'} Program Empty`,
      desc: `Let's create a new program for your customer`
    }
  }

  private onTabsClick(tab: number): void {
    this.currentTab = tab
    this.onParamsChanged('isActive', tab === 0)
    this.searchProgramID = this.cacheSearchProgramID
    controller.getAll(this.params)
  }

  private setLevelName(val: Array<string> | undefined): string {
    if (val) {
      if (val.every(v => v.trim() === '')) {
        return  '-'
      }
      val = val.map(v => {
        if (v.trim() === '') {
          return '-'
        } else {
          return Utils.toCapitalize(v)
        }
      })
      return val.join(' > ')
    }
    return '-'
  }
}
