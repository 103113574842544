
























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Badge from '@/app/ui/components/Badge/index.vue'
import ButtonBase from '@/app/ui/components/Button/index.vue'
import IconCheck from '@/app/ui/assets/ics_f_check_circle.vue'
import IconEye from '@/app/ui/assets/eye_filled.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import { Utils } from '@/app/infrastructures/misc'
import { EnumStatusLoyaltyMembership } from '@/domain/entities/LoyaltyProgramMembership';

@Component({
  name: 'MembershipItem',
  computed: {
    Utils() {
      return Utils
    }
  },
  components: {
    Badge,
    ButtonBase,
    EditIcon,
    IconCheck,
    IconEye,
  },
})
export default class MembershipItem extends Vue {
  @Prop({ default: '-', required: true }) private name!: string
  @Prop({ default: '-', required: true }) private statusProgram!: string
  @Prop({ default: '-', required: true }) private id!: number
  @Prop({ default: [], required: true }) private listShipmentSttPrefix!: Array<string>
  @Prop({ default: [], required: true }) private listShipmentId!: Array<string>
  @Prop({ default: [], required: true }) private listPackageStatus!: Array<string>
  @Prop( { default: false }) private isWithoutShipmentIdNonCa!: boolean
  @Prop({ default: '-', required: true }) private levelName!: string
  @Prop({ default: '-', required: true }) private startDate!: string
  @Prop({ default: '-', required: true }) private endDate!: string

  disableButton = true

  get getStatus(): EnumStatusLoyaltyMembership {
    return <EnumStatusLoyaltyMembership>this.statusProgram.toUpperCase()
  }

  showButtonEdit(): boolean {
    return [
      EnumStatusLoyaltyMembership.ACTIVE,
      EnumStatusLoyaltyMembership.PAUSED,
      EnumStatusLoyaltyMembership.SCHEDULED
    ].includes(this.getStatus)
  }
  showButtonStop(): boolean {
    return [
      EnumStatusLoyaltyMembership.ACTIVE,
      EnumStatusLoyaltyMembership.PAUSED,
      EnumStatusLoyaltyMembership.SCHEDULED
    ].includes(this.getStatus)
  }

  showButtonPaused(): boolean {
    return [EnumStatusLoyaltyMembership.ACTIVE].includes(this.getStatus)
  }

  isEnumStatus(): boolean {
    return Object.values(EnumStatusLoyaltyMembership).some(val => val === this.statusProgram.toUpperCase());
  }

  setHideBorderTopLoyaltyAction(): boolean {
    return [
      EnumStatusLoyaltyMembership.STOPPED,
      EnumStatusLoyaltyMembership.EXPIRED
    ].includes(this.getStatus)
  }

  showButtonStart(): boolean {
    return [EnumStatusLoyaltyMembership.PAUSED].includes(this.getStatus)
  }

  setBadgeTypeProgramStatus(): string {
    if (this.getStatus === EnumStatusLoyaltyMembership.ACTIVE) {
      return 'success'
    }

    if ([EnumStatusLoyaltyMembership.PAUSED, EnumStatusLoyaltyMembership.SCHEDULED].includes(this.getStatus)) {
      return this.getStatus.toLowerCase()
    }

    if ([EnumStatusLoyaltyMembership.STOPPED, EnumStatusLoyaltyMembership.EXPIRED].includes(this.getStatus)) {
      return 'error'
    }

    return 'expire'
  }
}
